var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var rH,Mfa,sH,wH,yH;rH=function(a,b,c,d,e){this.pattern=a;this.kd=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};Mfa=function(a,b){return new rH(a,b,null,null,null)};sH=function(a,b){$CLJS.Ie($CLJS.si($CLJS.r(a)),b)||$CLJS.dj.j(a,$CLJS.kf,b)};$CLJS.tH=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.N||a instanceof $CLJS.w){var b=$CLJS.qf(a);return $CLJS.m(b)?[b,"/",$CLJS.ui(a)].join(""):$CLJS.ui(a)}return null};
wH=function(a){a=$CLJS.A($CLJS.R.h(uH,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.$(null,d);sH($CLJS.vH,e);d+=1}else if(a=$CLJS.A(a))b=a,$CLJS.re(b)?(a=$CLJS.$c(b),c=$CLJS.ad(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.C(b),sH($CLJS.vH,e),a=$CLJS.D(b),b=null,c=0),d=0;else break};
yH=function(a){a=$CLJS.A($CLJS.R.h(uH,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.$(null,d);sH(xH,e);d+=1}else if(a=$CLJS.A(a))b=a,$CLJS.re(b)?(a=$CLJS.$c(b),c=$CLJS.ad(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.C(b),sH(xH,e),a=$CLJS.D(b),b=null,c=0),d=0;else break};var xH,AH;$CLJS.h=rH.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "pattern":return this.pattern;case "replacement":return this.kd;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zF,this.pattern],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AF,this.kd],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zF,$CLJS.AF],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1327743444^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.pattern,b.pattern)&&$CLJS.F.h(this.kd,b.kd)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.AF,null,$CLJS.zF,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new rH(this.pattern,this.kd,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "pattern":case "replacement":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.zF,b):$CLJS.O.call(null,$CLJS.zF,b))?new rH(c,this.kd,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.AF,b):$CLJS.O.call(null,$CLJS.AF,b))?new rH(this.pattern,c,this.F,this.m,null):new rH(this.pattern,this.kd,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.zF,this.pattern),new $CLJS.Je($CLJS.AF,this.kd)],null),this.m))};
$CLJS.h.O=function(a,b){return new rH(this.pattern,this.kd,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};var uH=function uH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uH.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};uH.l=function(a){return $CLJS.rg.h(function(b){return $CLJS.R.h(Mfa,b)},$CLJS.wu(2,2,a))};uH.v=0;uH.B=function(a){return this.l($CLJS.A(a))};
$CLJS.zH=$CLJS.cj.g(new $CLJS.ni(null,new $CLJS.k(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,
"ground",null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",
null,"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.cj.g(new $CLJS.k(null,2,["hst","HST","nasa","NASA"],null));$CLJS.vH=$CLJS.cj.g($CLJS.Lg);xH=$CLJS.cj.g($CLJS.Lg);
AH=$CLJS.cj.g(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.Sb($CLJS.ec,$CLJS.jn,0<b.length?new $CLJS.z(b.slice(0),0,null):null)}());
wH($CLJS.G([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
yH($CLJS.G([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.Ci($CLJS.rg.h(function(a){var b=$CLJS.C(a);a=$CLJS.Zd(a);b=$CLJS.tH(b).toLowerCase();a=$CLJS.tH(a).toLowerCase();$CLJS.dj.j($CLJS.zH,$CLJS.Gm,$CLJS.tH(b).toLowerCase());$CLJS.dj.j($CLJS.zH,$CLJS.Gm,$CLJS.tH(a).toLowerCase());yH($CLJS.G([$CLJS.Fi(["^",a,"$"].join("")),b]));wH($CLJS.G([$CLJS.Fi(["^",b,"$"].join("")),a]));$CLJS.dj.j(AH,$CLJS.kf,b);return $CLJS.dj.j(AH,$CLJS.kf,a)},new $CLJS.S(null,14,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,["amenity","amenities"],null),new $CLJS.S(null,2,5,$CLJS.T,
["child","children"],null),new $CLJS.S(null,2,5,$CLJS.T,["cow","kine"],null),new $CLJS.S(null,2,5,$CLJS.T,["foot","feet"],null),new $CLJS.S(null,2,5,$CLJS.T,["louse","lice"],null),new $CLJS.S(null,2,5,$CLJS.T,["mailman","mailmen"],null),new $CLJS.S(null,2,5,$CLJS.T,["man","men"],null),new $CLJS.S(null,2,5,$CLJS.T,["mouse","mice"],null),new $CLJS.S(null,2,5,$CLJS.T,["move","moves"],null),new $CLJS.S(null,2,5,$CLJS.T,["ox","oxen"],null),new $CLJS.S(null,2,5,$CLJS.T,["person","people"],null),new $CLJS.S(null,
2,5,$CLJS.T,["sex","sexes"],null),new $CLJS.S(null,2,5,$CLJS.T,["tooth","teeth"],null),new $CLJS.S(null,2,5,$CLJS.T,["woman","women"],null)],null)));