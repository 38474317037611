var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var sZ,tZ,Ana,xZ,yZ,Cna,zZ,AZ,CZ,DZ,EZ,FZ,GZ,IZ,JZ,KZ,Dna,NZ,OZ,Bna;
sZ=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lg,a],null)],null);for(var c=$CLJS.Lg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,y,B,H,I){return function(Q){return $CLJS.oh.j(v,$CLJS.rg.g(function(Y,aa,ha,qa,Ea){return function(kb){var lb=$CLJS.J(kb,0,null);kb=$CLJS.J(kb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kf.h(Ea,lb),kb],null)}}(q,u,v,x,y,B,H,I)),Q)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.kf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.dg($CLJS.Wm,g)):n}else return c}};tZ=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Ie(b,$CLJS.M.h(c,2))};$CLJS.uZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Nm.h($CLJS.KL,$CLJS.Zd)),$CLJS.NQ.g(a));return sZ($CLJS.Fm.l(a,$CLJS.TO,$CLJS.G([$CLJS.nZ])),function(c){return tZ($CLJS.zG,b,c)})};
$CLJS.vZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Nm.h($CLJS.HG,$CLJS.Zd)),$CLJS.LP.g(a));return sZ($CLJS.Fm.l(a,$CLJS.TO,$CLJS.G([$CLJS.nZ])),function(c){return tZ($CLJS.LP,b,c)})};Ana=function(a){function b(d){return $CLJS.sg(c,$CLJS.G([$CLJS.TO.g(d)]))}function c(d){return $CLJS.nf($CLJS.uP.g(d),$CLJS.sg(b,$CLJS.G([$CLJS.gZ.g(d)])))}return $CLJS.m($CLJS.wZ.g(a))?$CLJS.ag(!0):$CLJS.si(b(a))};
xZ=function(a){for(var b=$CLJS.ag(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),y=$CLJS.D(v),B=x,H=y,I=$CLJS.Pm.h(n,Ana(B));f=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(Q,Y,aa,ha,qa,Ea){return function Mb(lb,Fb){try{if($CLJS.qe(Fb)&&3===$CLJS.E(Fb))try{var ac=$CLJS.Td(Fb,0);if($CLJS.O(ac,$CLJS.CQ))try{var Tb=$CLJS.Td(Fb,1);if($CLJS.m($CLJS.kQ.g(Tb)))try{var Ub=$CLJS.Td(Fb,1);if(null!=Ub?Ub.C&256||$CLJS.t===Ub.bg||(Ub.C?0:$CLJS.Nb($CLJS.lc,Ub)):
$CLJS.Nb($CLJS.lc,Ub))try{var ge=$CLJS.M.j(Ub,$CLJS.kQ,$CLJS.YP);if($CLJS.m($CLJS.$f(ha)(ge))){var $z=$CLJS.M.h(Ub,$CLJS.kQ);$CLJS.Td(Fb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Ea),": no join named ",$CLJS.aj.l($CLJS.G([$z]))].join("")],null)}throw $CLJS.Z;}catch(Og){if(Og instanceof Error){var qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;
}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)return $CLJS.rZ(Mb,lb,Fb);throw qj;}throw Og;}}}(f,g,l,I,n,q,u,v,x,y,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Lg,$CLJS.Fm.l(B,$CLJS.TO,$CLJS.G([Bna]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;else return null}};
yZ=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);Cna=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);zZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);AZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.BZ=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);
CZ=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);DZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);EZ=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);FZ=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);
GZ=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.HZ=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);IZ=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);JZ=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);KZ=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);
$CLJS.LZ=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.wZ=new $CLJS.N(null,"source-card","source-card",-1580820390);Dna=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.MZ=new $CLJS.N(null,"filters","filters",974726919);NZ=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);OZ=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.PZ=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Bna=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.iL(EZ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.HZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SQ,$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nl,$CLJS.Lb],null)],null)],null));$CLJS.iL(yZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nl,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.AV],null)],null));
$CLJS.iL(JZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nl,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.AV],null)],null));$CLJS.iL(IZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nl,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.HL],null)],null));
var QZ=$CLJS.Pm.h(function(a){a=$CLJS.C($CLJS.uZ(a));return $CLJS.m(a)?["Invalid :expression reference: no expression named ",$CLJS.aj.l($CLJS.G([$CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null))]))].join(""):null},function(a){a=$CLJS.C($CLJS.vZ(a));return $CLJS.m(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.g($CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null)))].join(""):null});
$CLJS.iL(KZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ll,"Valid references for a single query stage",$CLJS.Yu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return QZ(a)}],null),$CLJS.$f(QZ)],null));
$CLJS.iL($CLJS.PZ,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.BZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TO,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.eZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NQ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.FL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.EP,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),yZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LP,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.lW],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vO,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),JZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MZ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),IZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WR,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.kZ],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XP,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.dV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wZ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.eV],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ie(a,$CLJS.jR)}],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.Om.h($CLJS.XP,$CLJS.wZ))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,KZ],null)],null));$CLJS.iL(zZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XP,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.dV],null)],null)],null)],null));
$CLJS.iL(AZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.eV],null)],null)],null)],null));$CLJS.iL(DZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,zZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,AZ],null)],null));
$CLJS.iL(FZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.Pm.h($CLJS.XP,$CLJS.wZ))],null)],null));$CLJS.iL(GZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cm,$CLJS.HZ,$CLJS.BZ],null));
$CLJS.iL(Cna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,GZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ot,new $CLJS.k(null,1,[$CLJS.wk,$CLJS.sl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,EZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PZ],null)],null)],null)],null));
$CLJS.iL(OZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,GZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ot,new $CLJS.k(null,1,[$CLJS.wk,$CLJS.sl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,EZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,DZ],null)],null)],null)],null));$CLJS.iL(CZ,FZ);
$CLJS.iL(NZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ll,"Valid references for all query stages",$CLJS.Yu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return xZ.g?xZ.g(a):xZ.call(null,a)}],null),$CLJS.$f(xZ)],null));
$CLJS.iL($CLJS.cZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,OZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,CZ],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,NZ],null)],null));
$CLJS.iL(Dna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.LZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.fV,$CLJS.gV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.cZ],null)],null)],null),$CLJS.zna],null));