var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./schema.utils.js");require("./schema.spec.core.js");require("./schema.spec.leaf.js");require("./schema.spec.variant.js");require("./schema.spec.collection.js");
'use strict';var Mga,NM,OM,QM,SM,UM,Nga,WM,YM,ZM,Oga,$M,Pga,aN,Sga,Tga,dN,eN,hN,Wga,Xga,Yga,bN,Zga,$ga,cN,aha,kN,Qga,cha,mN,fha,gN,hha,Lga,kha,Rga,mha,nha,rN,oha,pha,qha,tN,uN,vN,xN,yN,tha,uha,vha;$CLJS.KM=function(){return null};$CLJS.LM=function(a){if(null!=a&&null!=a.Db)a=a.Db(a);else{var b=$CLJS.LM[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$CLJS.LM._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("Schema.spec",a);}return a};
$CLJS.MM=function(a){if(null!=a&&null!=a.Fa)a=a.Fa(a);else{var b=$CLJS.MM[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$CLJS.MM._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("Schema.explain",a);}return a};Mga=function(a,b){return $CLJS.iM(a,function(c){var d=null!=c;return d?(d=b===c.constructor)?d:c instanceof b:d},function(c){return new $CLJS.gf(null,Lga,new $CLJS.gf(null,b,new $CLJS.gf(null,c,null,1,null),2,null),3,null)})};
NM=function(a,b,c,d){this.ic=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};OM=function(a,b,c,d){this.ua=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.PM=function(a){return new OM(a,null,null,null)};QM=function(a,b,c,d,e){this.ec=a;this.Jc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};$CLJS.RM=function(a,b){if(!$CLJS.ze(a))throw Error($CLJS.TL("Not a function: %s",$CLJS.G([a])));return new QM(a,b,null,null,null)};
SM=function(a,b,c,d,e){this.X=a;this.name=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};$CLJS.TM=function(a,b){return new SM(a,b,null,null,null)};UM=function(a,b,c,d,e,f){this.X=a;this.Ic=b;this.vc=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};Nga=function(a,b){return $CLJS.VM(a,b,$CLJS.Ui.g($CLJS.WL(b)))};$CLJS.VM=function(a,b,c){if(!$CLJS.ze(b))throw Error($CLJS.TL("Not a function: %s",$CLJS.G([b])));return new UM(a,b,c,null,null,null)};
WM=function(a){return a instanceof $CLJS.N||!1};$CLJS.XM=function(a,b,c,d){this.k=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};YM=function(a){if(a instanceof $CLJS.N)return a;if(a instanceof $CLJS.XM)return a.k;throw Error($CLJS.TL("Bad explicit key: %s",$CLJS.G([a])));};ZM=function(a){return WM(a)||a instanceof $CLJS.XM};Oga=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.Je(b,a)};
$M=function(a,b,c,d,e){this.Cb=a;this.Mc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};Pga=function(a){a=$CLJS.Qm.h(ZM,$CLJS.gi(a));if(!(2>$CLJS.E(a)))throw Error($CLJS.TL("More than one non-optional/required key schemata: %s",$CLJS.G([$CLJS.Mg(a)])));return $CLJS.C(a)};aN=function(a){return ZM(a)?a instanceof $CLJS.N?a:new $CLJS.gf(null,WM(a)?Qga:a instanceof $CLJS.XM?Rga:null,new $CLJS.gf(null,YM(a),null,1,null),2,null):$CLJS.MM(a)};
Sga=function(a){var b=Pga(a),c=$CLJS.Rm.h(aN,$CLJS.R.h($CLJS.qg,$CLJS.ug(function(e){return 1<$CLJS.E(e)},$CLJS.ii($CLJS.fj(YM,$CLJS.gi($CLJS.Fm.h(a,b)))))));if(!$CLJS.je(c))throw Error($CLJS.TL("Schema has multiple variants of the same explicit key: %s",$CLJS.G([c])));var d=$CLJS.Fm.h(a,b);return $CLJS.qg.h(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){var n=l;if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var H=
0;;)if(H<u){var I=$CLJS.be(q,H),Q=$CLJS.J(I,0,null),Y=$CLJS.J(I,1,null);$CLJS.Ff(v,function(){var aa=YM(Q),ha=WM(Q);return $CLJS.GM(ha,new $M($CLJS.PM(aa),Y,null,null,null),function(qa,Ea,kb){return function(lb,Fb){var Mb=$CLJS.Ke(Fb,Ea);if($CLJS.m(Mb))lb.g?lb.g(Mb):lb.call(null,Mb);else if(kb){var ac=$CLJS.eM(new $CLJS.S(null,2,5,$CLJS.T,[Ea,bN],null));lb.g?lb.g(ac):lb.call(null,ac)}return $CLJS.m(Mb)?$CLJS.Fm.h(Fb,Ea):Fb}}(H,aa,ha,I,Q,Y,q,u,v,n,l,d,b))}());H+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),
g($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n),y=$CLJS.J(x,0,null),B=$CLJS.J(x,1,null);return $CLJS.nf(function(){var H=YM(y),I=WM(y);return $CLJS.GM(I,new $M($CLJS.PM(H),B,null,null,null),function(Q,Y){return function(aa,ha){var qa=$CLJS.Ke(ha,Q);if($CLJS.m(qa))aa.g?aa.g(qa):aa.call(null,qa);else if(Y){var Ea=$CLJS.eM(new $CLJS.S(null,2,5,$CLJS.T,[Q,bN],null));aa.g?aa.g(Ea):aa.call(null,Ea)}return $CLJS.m(qa)?$CLJS.Fm.h(ha,Q):ha}}(H,I,x,y,B,n,l,d,b))}(),g($CLJS.zd(n)))}return null}},
null,null)}(d)}(),$CLJS.m(b)?function(){var e=$CLJS.si($CLJS.rg.h(YM,$CLJS.gi(d))),f=$CLJS.Ke(a,b),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=Nga(g,function(l){return!$CLJS.Ie(e,l)});return new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.EM(new $M(g,f,null,null,null))],null)}():null)};
Tga=function(){return function(a,b,c){return $CLJS.oh.h($CLJS.P,$CLJS.qg.h($CLJS.eg($CLJS.gM,b),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.J(v,0,null);$CLJS.J(v,1,null);q.add(new $CLJS.S(null,2,5,$CLJS.T,[x,cN],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.J(q,
0,null);$CLJS.J(q,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,cN],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}()))}};dN=function(a){return $CLJS.DM($CLJS.iM(a,$CLJS.oe,function(b){return new $CLJS.gf(null,$CLJS.ek,new $CLJS.gf(null,b,null,1,null),2,null)}),function(b){return $CLJS.oh.h($CLJS.P,b)},Sga(a),Tga())};
eN=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[aN(u),$CLJS.MM(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[aN(l),$CLJS.MM(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};$CLJS.fN=function(a,b,c,d,e,f){this.X=a;this.tc=b;this.name=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};
hN=function(a){var b=$CLJS.Bi(function(e){return e instanceof $CLJS.fN&&$CLJS.Gb(gN.g(e))},a),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.Bi(function(e){var f=e instanceof $CLJS.fN;return f?gN.g(e):f},b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if(!(1>=$CLJS.E(d)&&$CLJS.Yf(function(e){return e instanceof $CLJS.fN},d)))throw Error($CLJS.TL("%s is not a valid sequence schema; %s%s%s",$CLJS.G([a,"a valid sequence schema consists of zero or more `one` elements, ","followed by zero or more `optional` elements, followed by an optional ",
"schema that will match the remaining elements."])));return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qg.h(c,b),$CLJS.C(d)],null)};$CLJS.Uga=new $CLJS.w(null,"some-matching-condition?","some-matching-condition?",1512398506,null);$CLJS.Vga=new $CLJS.w(null,"conditional","conditional",-1212542970,null);Wga=new $CLJS.w(null,"Uuid","Uuid",-1866694318,null);Xga=new $CLJS.w(null,"has-extra-elts?","has-extra-elts?",-1376562869,null);Yga=new $CLJS.w(null,"Int","Int",-2116888740,null);
bN=new $CLJS.w(null,"missing-required-key","missing-required-key",709961446,null);Zga=new $CLJS.w(null,"present?","present?",-1810613791,null);$ga=new $CLJS.w(null,"one","one",-1719427865,null);$CLJS.iN=new $CLJS.N(null,"schemas","schemas",575070579);cN=new $CLJS.w(null,"disallowed-key","disallowed-key",-1877785633,null);$CLJS.jN=new $CLJS.N(null,"input-schemas","input-schemas",-982154805);aha=new $CLJS.w(null,"Keyword","Keyword",-850065993,null);kN=new $CLJS.N(null,"pred-name","pred-name",-3677451);
$CLJS.bha=new $CLJS.w(null,"\x3d\x3e*","\x3d\x3e*",1909690043,null);$CLJS.lN=new $CLJS.N(null,"output-schema","output-schema",272504137);Qga=new $CLJS.w(null,"required-key","required-key",1624616412,null);cha=new $CLJS.w(null,"re-find","re-find",1143444147,null);$CLJS.dha=new $CLJS.w(null,"cond-pre","cond-pre",-923707731,null);$CLJS.eha=new $CLJS.N("schema.core","error","schema.core/error",1991454308);mN=new $CLJS.N(null,"p?","p?",-1172161701);
fha=new $CLJS.w(null,"constrained","constrained",-2057147788,null);gN=new $CLJS.N(null,"optional?","optional?",1184638129);$CLJS.nN=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);$CLJS.oN=new $CLJS.N(null,"vs","vs",-2022097090);$CLJS.gha=new $CLJS.w(null,"\x3d\x3e","\x3d\x3e",-813269641,null);$CLJS.pN=new $CLJS.N(null,"error-symbol","error-symbol",-823480428);hha=new $CLJS.w(null,"Inst","Inst",292408622,null);$CLJS.iha=new $CLJS.w(null,"recursive","recursive",-1935549897,null);
Lga=new $CLJS.w(null,"instance?","instance?",1075939923,null);$CLJS.jha=new $CLJS.w(null,"maybe","maybe",1326133967,null);kha=new $CLJS.N("schema.core","missing","schema.core/missing",1420181325);$CLJS.lha=new $CLJS.w(null,"matches-some-precondition?","matches-some-precondition?",2123072832,null);$CLJS.qN=new $CLJS.N(null,"preds-and-schemas","preds-and-schemas",-1306766355);Rga=new $CLJS.w(null,"optional-key","optional-key",988406145,null);mha=new $CLJS.w(null,"Symbol","Symbol",716452869,null);
nha=new $CLJS.w(null,"Str","Str",907970895,null);rN=new $CLJS.N(null,"postcondition","postcondition",-737101222);$CLJS.sN=new $CLJS.N(null,"derefable","derefable",377265868);oha=new $CLJS.w(null,"eq","eq",1021992460,null);pha=new $CLJS.w(null,"Bool","Bool",195910502,null);qha=new $CLJS.w(null,"invalid-key","invalid-key",-1461682245,null);tN=new $CLJS.N(null,"key-schema","key-schema",-1096660726);uN=new $CLJS.N(null,"post-name","post-name",491455269);vN=new $CLJS.N(null,"val-schema","val-schema",-2014773619);
$CLJS.wN=new $CLJS.w(null,"optional","optional",-600484260,null);$CLJS.rha=new $CLJS.w(null,"enum","enum",-975417337,null);xN=new $CLJS.N(null,"v","v",21465059);$CLJS.sha=new $CLJS.w(null,"...","...",-1926939749,null);yN=new $CLJS.N(null,"_","_",1453416199);tha=new $CLJS.w(null,"Any","Any",1277492269,null);uha=new $CLJS.w(null,"map-entry","map-entry",329617471,null);vha=new $CLJS.w(null,"Num","Num",-2044934708,null);$CLJS.LM["function"]=function(a){var b=Mga(a,a);a=$CLJS.Ua(a,"schema$utils$schema");return $CLJS.m(a)?$CLJS.wM(b,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.em,a],null)],null)):$CLJS.qM(b)};
$CLJS.MM["function"]=function(a){var b=$CLJS.Ua(a,"schema$utils$schema");return $CLJS.m(b)?$CLJS.MM(b):$CLJS.m($CLJS.F.h?$CLJS.F.h(Boolean,a):$CLJS.F.call(null,Boolean,a))?pha:$CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a))?vha:$CLJS.m($CLJS.F.h?$CLJS.F.h(Date,a):$CLJS.F.call(null,Date,a))?hha:$CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.Hu,a):$CLJS.F.call(null,$CLJS.Hu,a))?Wga:a};$CLJS.h=NM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "_":return this.ic;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.AnythingSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[yN,this.ic],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[yN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1432036169^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ic,b.ic)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[yN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new NM(this.ic,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "_":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(yN,b):$CLJS.O.call(null,yN,b))?new NM(c,this.F,this.m,null):new NM(this.ic,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je(yN,this.ic)],null),this.m))};$CLJS.h.O=function(a,b){return new NM(this.ic,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.qM($CLJS.KM)};$CLJS.h.Fa=function(){return tha};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.zN=new NM(null,null,null,null);$CLJS.h=OM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "v":return this.ua;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EqSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[xN,this.ua],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[xN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-907989292^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ua,b.ua)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[xN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new OM(this.ua,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "v":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(xN,b):$CLJS.O.call(null,xN,b))?new OM(c,this.F,this.m,null):new OM(this.ua,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je(xN,this.ua)],null),this.m))};$CLJS.h.O=function(a,b){return new OM(this.ua,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.qM($CLJS.iM(this,function(b){return $CLJS.F.h(a.ua,b)},function(b){return new $CLJS.gf(null,$CLJS.nN,new $CLJS.gf(null,a.ua,new $CLJS.gf(null,b,null,1,null),2,null),3,null)}))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,oha,new $CLJS.gf(null,this.ua,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
$CLJS.h=QM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "p?":return this.ec;case "pred-name":return this.Jc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Predicate{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[mN,this.ec],null),new $CLJS.S(null,2,5,$CLJS.T,[kN,this.Jc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[mN,kN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2041221968^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ec,b.ec)&&$CLJS.F.h(this.Jc,b.Jc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[kN,null,mN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new QM(this.ec,this.Jc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "p?":case "pred-name":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(mN,b):$CLJS.O.call(null,mN,b))?new QM(c,this.Jc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(kN,b):$CLJS.O.call(null,kN,b))?new QM(this.ec,c,this.F,this.m,null):new QM(this.ec,this.Jc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je(mN,this.ec),new $CLJS.Je(kN,this.Jc)],null),this.m))};$CLJS.h.O=function(a,b){return new QM(this.ec,this.Jc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.qM($CLJS.iM(this,a.ec,function(b){return new $CLJS.gf(null,a.Jc,new $CLJS.gf(null,b,null,1,null),2,null)}))};
$CLJS.h.Fa=function(){return $CLJS.F.h(this.ec,$CLJS.Ae)?Yga:$CLJS.F.h(this.ec,$CLJS.pf)?aha:$CLJS.F.h(this.ec,$CLJS.rd)?mha:$CLJS.F.h(this.ec,$CLJS.Jb)?nha:new $CLJS.gf(null,$CLJS.rm,new $CLJS.gf(null,this.Jc,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
RegExp.prototype.Db=function(){var a=this;return $CLJS.qM($CLJS.Pm.h($CLJS.iM(a,$CLJS.Jb,function(b){return new $CLJS.gf(null,$CLJS.Vl,new $CLJS.gf(null,b,null,1,null),2,null)}),$CLJS.iM(a,function(b){return $CLJS.Ei(a,b)},function(b){return new $CLJS.gf(null,cha,new $CLJS.gf(null,$CLJS.MM(a),new $CLJS.gf(null,b,null,1,null),2,null),3,null)})))};RegExp.prototype.Fa=function(){return $CLJS.Ui.g(['#"',$CLJS.p.g($CLJS.p.g(this).slice(1,-1)),'"'].join(""))};$CLJS.AN=$CLJS.RM($CLJS.Jb,$CLJS.Vl);
$CLJS.BN=Boolean;$CLJS.CN=Number;$CLJS.DN=$CLJS.RM($CLJS.Ae,$CLJS.tm);$CLJS.EN=$CLJS.RM($CLJS.pf,$CLJS.Ck);$CLJS.RM($CLJS.rd,$CLJS.Xj);$CLJS.h=SM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "name":return this.name;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.NamedSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,this.name],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.V],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=456601728^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.name,b.name)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.em,null,$CLJS.V,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new SM(this.X,this.name,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.em,b):$CLJS.O.call(null,$CLJS.em,b))?new SM(c,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.V,b):$CLJS.O.call(null,$CLJS.V,b))?new SM(this.X,c,this.F,this.m,null):new SM(this.X,this.name,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.em,this.X),new $CLJS.Je($CLJS.V,this.name)],null),this.m))};
$CLJS.h.O=function(a,b){return new SM(this.X,this.name,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.wM($CLJS.KM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.em,a.X,$CLJS.tM,function(b){return new $CLJS.aM(a.name,b)}],null)],null))};
$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.bM,new $CLJS.gf(null,$CLJS.MM(this.X),new $CLJS.gf(null,this.name,null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.pM.prototype.Sd=$CLJS.La(59);$CLJS.uM.prototype.Sd=$CLJS.La(58);$CLJS.CM.prototype.Sd=$CLJS.La(57);$CLJS.h=UM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "postcondition":return this.Ic;case "post-name":return this.vc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Constrained{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[rN,this.Ic],null),new $CLJS.S(null,2,5,$CLJS.T,[uN,this.vc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.em,rN,uN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=725895648^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.Ic,b.Ic)&&$CLJS.F.h(this.vc,b.vc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.em,null,uN,null,rN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new UM(this.X,this.Ic,this.vc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "postcondition":case "post-name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.em,b):$CLJS.O.call(null,$CLJS.em,b))?new UM(c,this.Ic,this.vc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rN,b):$CLJS.O.call(null,rN,b))?new UM(this.X,c,this.vc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(uN,b):$CLJS.O.call(null,uN,b))?new UM(this.X,this.Ic,c,this.F,this.m,null):new UM(this.X,this.Ic,this.vc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je($CLJS.em,this.X),new $CLJS.Je(rN,this.Ic),new $CLJS.Je(uN,this.vc)],null),this.m))};$CLJS.h.O=function(a,b){return new UM(this.X,this.Ic,this.vc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.xM($CLJS.KM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.em,a.X],null)],null),null,$CLJS.iM(this,a.Ic,function(b){return new $CLJS.gf(null,a.vc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,fha,new $CLJS.gf(null,$CLJS.MM(this.X),new $CLJS.gf(null,this.vc,null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=$CLJS.XM.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "k":return this.k;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.OptionalKey{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.av,this.k],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.av],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1508333161^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.k,b.k)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.av,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.XM(this.k,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "k":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.av,b):$CLJS.O.call(null,$CLJS.av,b))?new $CLJS.XM(c,this.F,this.m,null):new $CLJS.XM(this.k,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.av,this.k)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.XM(this.k,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=$M.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "key-schema":return this.Cb;case "val-schema":return this.Mc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.MapEntry{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[tN,this.Cb],null),new $CLJS.S(null,2,5,$CLJS.T,[vN,this.Mc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[tN,vN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-804356212^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Cb,b.Cb)&&$CLJS.F.h(this.Mc,b.Mc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[tN,null,vN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $M(this.Cb,this.Mc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "key-schema":case "val-schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(tN,b):$CLJS.O.call(null,tN,b))?new $M(c,this.Mc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(vN,b):$CLJS.O.call(null,vN,b))?new $M(this.Cb,c,this.F,this.m,null):new $M(this.Cb,this.Mc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je(tN,this.Cb),new $CLJS.Je(vN,this.Mc)],null),this.m))};$CLJS.h.O=function(a,b){return new $M(this.Cb,this.Mc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){return $CLJS.DM($CLJS.KM,Oga,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GM(!0,this.Cb,function(a,b){var c=$CLJS.tc(b);a.g?a.g(c):a.call(null,c);return b}),$CLJS.GM(!0,this.Mc,function(a,b){b=$CLJS.uc(b);a.g?a.g(b):a.call(null,b);return null})],null),function(a,b){a=$CLJS.J(a,0,null);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.gM(c);return $CLJS.m(c)?new $CLJS.S(null,2,5,$CLJS.T,[c,qha],null):new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.gM(b)],null)})};
$CLJS.h.Fa=function(){return new $CLJS.gf(null,uha,new $CLJS.gf(null,$CLJS.MM(this.Cb),new $CLJS.gf(null,$CLJS.MM(this.Mc),null,1,null),2,null),3,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.k.prototype.Db=function(){return dN(this)};$CLJS.k.prototype.Fa=function(){return eN(this)};$CLJS.Rh.prototype.Db=function(){return dN(this)};$CLJS.Rh.prototype.Fa=function(){return eN(this)};
$CLJS.ni.prototype.Db=function(){if(!$CLJS.F.h($CLJS.E(this),1))throw Error($CLJS.UL("Set schema must have exactly one element"));return $CLJS.DM($CLJS.iM(this,$CLJS.le,function(a){return new $CLJS.gf(null,$CLJS.gl,new $CLJS.gf(null,a,null,1,null),2,null)}),$CLJS.si,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.EM($CLJS.C(this))],null),function(a,b){return $CLJS.si($CLJS.eg($CLJS.gM,b))})};$CLJS.ni.prototype.Fa=function(){return $CLJS.si(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.MM($CLJS.C(this))],null))};
$CLJS.h=$CLJS.fN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;case "optional?":return this.tc;case "name":return this.name;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.One{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,this.X],null),new $CLJS.S(null,2,5,$CLJS.T,[gN,this.tc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,this.name],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.em,gN,$CLJS.V],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-197981045^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.tc,b.tc)&&$CLJS.F.h(this.name,b.name)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.em,null,$CLJS.V,null,gN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.fN(this.X,this.tc,this.name,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":case "optional?":case "name":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.em,b):$CLJS.O.call(null,$CLJS.em,b))?new $CLJS.fN(c,this.tc,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(gN,b):$CLJS.O.call(null,gN,b))?new $CLJS.fN(this.X,c,this.name,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.V,b):$CLJS.O.call(null,$CLJS.V,b))?new $CLJS.fN(this.X,this.tc,c,this.F,this.m,null):new $CLJS.fN(this.X,this.tc,this.name,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je($CLJS.em,this.X),new $CLJS.Je(gN,this.tc),new $CLJS.Je($CLJS.V,this.name)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.fN(this.X,this.tc,this.name,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.S.prototype.Db=function(){var a=this;return $CLJS.DM($CLJS.iM(a,function(b){return null==b||$CLJS.ne(b)},function(b){return new $CLJS.gf(null,$CLJS.fl,new $CLJS.gf(null,b,null,1,null),2,null)}),$CLJS.Mg,function(){var b=hN(a),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.Sb(function(d,e){return $CLJS.Gb(e.tc)?$CLJS.nf($CLJS.GM(!0,$CLJS.TM(e.X,e.name),function(f,g){if(g=$CLJS.A(g)){var l=$CLJS.C(g);f.g?f.g(l):f.call(null,l);return $CLJS.zd(g)}g=$CLJS.eM($CLJS.$L(e.X,kha,new $CLJS.Yi(function(){return new $CLJS.gf(null,
Zga,new $CLJS.gf(null,e.name,null,1,null),2,null)}),null));f.g?f.g(g):f.call(null,g);return null}),d):new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Kga($CLJS.TM(e.X,e.name),function(f,g){if(g=$CLJS.A(g)){var l=$CLJS.C(g);f.g?f.g(l):f.call(null,l);return $CLJS.zd(g)}return null},d)],null)},$CLJS.m(b)?new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.EM(b)],null):null,$CLJS.lf(c))}(),function(b,c,d){b=$CLJS.Rm.h($CLJS.gM,c);return $CLJS.A(d)?$CLJS.kf.h(b,$CLJS.gM($CLJS.eM($CLJS.$L(null,d,new $CLJS.Yi(function(){return new $CLJS.gf(null,
Xga,new $CLJS.gf(null,$CLJS.E(d),null,1,null),2,null)}),null)))):b})};$CLJS.S.prototype.Fa=function(){var a=hN(this),b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.Rm.h(function(c){return new $CLJS.gf(null,$CLJS.m(c.tc)?$CLJS.wN:$ga,new $CLJS.gf(null,$CLJS.MM($CLJS.em.g(c)),new $CLJS.gf(null,$CLJS.V.g(c),null,1,null),2,null),3,null)},b);return $CLJS.m(a)?$CLJS.kf.h(b,$CLJS.MM(a)):b};$CLJS.cj.g(!1);